body {
  margin: 0 !important;
}

a {
  color: #47b2e4;
  text-decoration: none;
}

a:hover {
  color: #73c5eb;
  text-decoration: none;
}

section {
  
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  background-image: url("../Images/background-2.png");
  background-position: center bottom;
  min-height: 400px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  margin-bottom: 60px;
}

.section-title h2 {
  font-size: 50px;
  font-weight: bold;
  color: #ffffff;
  text-align: left;
  padding-left: 30px;
}