.about {
    padding-bottom: 60px;
}

.about-us-wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);
}

.about-top-section {
    width: 100%;
    height: 80px;
    background: #37517e;
}

@media only screen and (max-width: 600px) {
    .about-top-section {
        height: 66px;
    }
}