.cloud-computing-us-wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);
}

.cloud-computing-top-section {
    width: 100%;
    height: 80px;
    background: #37517e;
}

.cloud-computing {
    padding-bottom: 60px;
}