.breadCrumb {
  text-align: left;
  padding-left: 30px;
  /* margin-left: -14.2px; */
}
.breadCrumb > span:first-child > span:first-child{
  margin-left: 0px !important;
}

.breadCrumb .firstPage {
    font-size: 20px;
    color: #ffffff;
    font-weight: unset;
    margin-top: 43px;
    margin-left: 14.2px;
    vertical-align: middle;
    cursor: pointer;
  }

  .breadCrumb .currentPage {
    font-size: 20px;
    color: #ffffff;
    font-weight: unset;
    margin-top: 43px;
    margin-left: 8px;
    vertical-align: middle;
  }

  .breadCrumb .link:visited {
    color: #007db8;
  }
  @media only screen and (max-width: 600px) {
    .breadCrumb .firstPage, .breadCrumb .currentPage {
      font-size: 15px;
    }
  }