
.vendor-staffing-us-wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);
}

.vendor-staffing-top-section {
    width: 100%;
    height: 80px;
    background: #37517e;
}

.heading {
    font-size: 1.5rem;
    font-weight: bold;
}

.vendor-staffing ul {
    margin-left: 20px;
}

.vendor-staffing {
    padding-bottom: 60px;
}