.section-text {
    padding-bottom: 30px;
}

.contact-container {
    display: flex;
    min-height: 600px;
    margin-bottom: 60px;
}

.contact-top-section {
    width: 100%;
    height: 80px;
    background: #37517e;
}

.contact-location-container {
    margin-right: 20px;
    border-top: 3px solid #47b2e4;
    border-bottom: 3px solid #47b2e4;
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgb(0 0 0 / 10%);
    width: 30%;
}

.contact-location-container .info {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.contact-location-container .info .maps {
    flex-grow: 1;
}

.contact .info .icon-wrapper {
    font-size: 30px;
    color: #47b2e4;
    float: left;
    min-width: 44px !important;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    margin-right: 20px !important;
}

.contact .info .icon-wrapper svg {
    /* transform: scale(0.5); */
}

.contact-message-container {
    margin-left: 20px;
    /* border-top: 3px solid #47b2e4;
    border-bottom: 3px solid #47b2e4; */
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgb(0 0 0 / 10%);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact-message-container label {
    padding-bottom: 8px;
    display: block;
}

.first-row {
    display: flex;
}

.mb-20 {
    margin-bottom: 20px;
}

/* .contact-message-name {
    width: 50%;
    margin-right: 10px;
} */

.contact-message-name-input,
.contact-message-email-input,
.contact-message-subject-input {
    width: 100%;
    height: 44px;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.contact-message-name-input:focus,
.contact-message-subject-input:focus,
.contact-message-subject-input:focus,
.contact-message-textarea:focus {
    border: 1px solid #47b2e4 !important;
    outline: none !important;
}

/* .contact-message-email {
    width: 50%;
    margin-left: 10px;
} */

.third-row {
    flex-grow: 1;
}

.contact-message-textarea {
    width: 100%;
    /* height: 672px; */
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.send-message {
    display: flex;
    /* justify-content: center; */
}

.send-message-button {
    padding: 12px 34px;
    color: #fff;
    transition: 0.4s;
    background-color: #2a3855;
    border-color: #222d44;
}

.send-message-button:disabled {
    opacity: 0.7;
}

.address {
    display: flex;
}

.loader {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 100%;
    align-items: center;
}

.loader > div {
    height: 80px;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 101;
    cursor: pointer;
    opacity: 0.8;
  }
  
  .overlay-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  .overlay-text {
    text-align: center;
    margin: 20px;
    clear: both;
    display: block;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.5px;
    color: #2B7FDA;
    opacity: 1;
  }

@media only screen and (max-width: 600px) {
    .first-row {
        flex-direction: column;
    }

    .contact-message-name {
        width: 100%;
    }

    .contact-message-email {
        width: 100%;
        margin-left: 0;
    }
    .contact-message-container {
        margin: 0;
    }
}