#header {
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
}

#header.header-scrolled, #header.header-inner-pages {
    background: #ffffff;
  }

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
}

.me-auto {
    margin-right: auto!important;
}

#header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#header .logo a {
    color: #2a3855;
}



.navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar span, .navbar span:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 20px;
    font-weight: 500;
    color: #2a3855;
    white-space: nowrap;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .navbar span i, .navbar span:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  .navbar span:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > span {
    color: #000000;
  }

  .nav-link {
    cursor: pointer;
  }

  .menu-mobile {
    display: none;
  }

  @media only screen and (max-width: 700px) {
    #header .logo {
      font-size: 20px; 
    }
    .desktop {
      display: none;
    }
    .menu-mobile {
      display: block;
    }
    .me-auto {
      /* margin-right: unset !important; */
    }
  }