.services-wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);
}

.services {
    background-color: #f3f5fa;
    flex-grow: 1;
    padding-bottom: 60px;
}

.services-top-section {
    width: 100%;
    height: 80px;
    background: #37517e;
}

.service-box {
    background-color: #ffffff;
    box-shadow: 0px 0 25px 0 rgb(0 0 0 / 10%);
    padding: 50px 30px;
    transition: all ease-in-out 0.4s;
    min-height: 300px;
    margin: 0 25px;
    cursor: pointer;
    transition: all 1s;
    width: 33%;
}

.services-box-container {
    display: flex;
}

.service-box-header {
    color: #37517e;
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 24px;
    text-align: center;
}

.service-box-content {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.service-box:hover {
    position: relative;
    bottom: 20px;
    transform: scale(1);
}

.service-box:first-of-type {
    margin-left: 0 !important;
}

.service-box:last-of-type {
    margin-right: 0 !important;
}


.services-box {
    display: flex;
    min-height: 400px;
}

.services-box-image1 {
    width: calc(50% + 25px);
    background-image: url("../Images/tcsimage1.png");
    background-position: center;
    left: -25px;
    position: relative;
}

.services-box-content1 {
    width: 50%;
    background-color: #ffffff;
    right: 25px;
    position: relative;
    padding: 64px 48px;
}

.services-box-image2 {
    width: 50%;
    background-image: url("../Images/tcsimage2.png");
    background-position: center;
    right: 28px;
    position: relative;
}

.services-box-content2 {
    width: calc(50% + 25px);
    background-color: #ffffff;
    left: -25px;
    position: relative;
    padding: 64px 48px;
}

.services-box-image3 {
    width: calc(50% + 25px);
    background-image: url("../Images/tcsimage3.png");
    background-position: center;
    left: -25px;
    position: relative;
}

.services-box-content3 {
    width: 50%;
    background-color: #ffffff;
    right: 25px;
    position: relative;
    padding: 64px 48px;
}

.elixir-caret {
    position: relative;
    background-color: #ffffff;
    top: 188px;
    left: calc(50% - 3px);
    height: 25px;
    width: 25px;
    z-index: 99;
    -webkit-transform: translate3d(-50%,-50%,0) rotate(45deg);
    transform: translate3d(-50%,-50%,0) rotate(45deg);
}

.services-box-header {
    margin-bottom: 8px;
    color: #2a3855;
    font-weight: 700;
    font-size: 1.5rem;
}

.services-box-content {
    margin: 16px 0;
    color: #6a6a6a;
    font-size: 1rem;
}

.services-box-link {
    cursor: pointer;
    color: #2a3855;
    font-weight: 600;
    font-size: 1.2rem;
}

.services-box-link:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .services-top-section {
        height: 66px;
    }

    .services-box-container {
        flex-direction: column;
    }

    .service-box {
        margin: 0 0 24px 0;
        width: 100%;
    }

    .services-wrapper .services-box {
        margin: 20px 0;
    }
}

@media only screen and (min-width: 600px){
    
    .services-container{
        width: 100% !important;
        max-width: 100%;
    }
    
}