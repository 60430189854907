.home {
    width: 100%;
    height: 80vh;
    /* background: #37517e; */
    /* display: flex; */
}

.home-page-wrapper {
    min-height: 100vh;
}

.home-text {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #2a3855 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.home-text-1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #2a3855 !important;
    height: 100%;
    min-height: 80vh;
    background-image: url("../Images/header-1.jpeg");
    width: 100%;
    min-width: 100vw;
    background-size: cover;
    display: flex;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    padding-left: 50px;
}

.home-text-2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #2a3855 !important;
    height: 100%;
    min-height: 80vh;
    background-image: url("../Images/header-2.jpeg");
    width: 100%;
    min-width: 100vw;
    background-size: cover;
    display: flex;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    padding-left: 50px;
}

.home-text-3 {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #2a3855 !important;
    height: 100%;
    min-height: 80vh;
    background-image: url("../Images/header-3.jpeg");
    width: 100%;
    min-width: 100vw;
    background-size: cover;
    display: flex;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    padding-left: 50px;
}

.home-sub-text {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    width: 50%;
    margin-top: 20px;
}

.home-image-wrapper {
    width: 50%;
    display: flex;
}

.home img {
    max-width: 80%;
    max-height: 80%;
    margin: auto;
}

.home-page-content-wrapper {
    display: flex;
    margin: 60px auto;
    max-width: 1140px;
}

.home-page-content-container {
    margin-right: 20px;
    border-top: 3px solid #47b2e4;
    border-bottom: 3px solid #47b2e4;
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgb(0 0 0 / 10%);
    width: 33%;
}

.home-page-content-header {
    color: #37517e;
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 24px;
    text-align: center;
}

.home-page-content-content {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.home-content-wrapper {
    padding: 60px 0;
}

.carousel-root {
    height: 100% !important;
    min-height: 80vh;
}

.carousel.carousel-slider {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-top-section-header {
    width: 100%;
    height: 80px;
    background: #37517e;
}

.slider-wrapper, .slider {
    height: 100%;
}

.home-image-1 {
    background-image: url("../Images/header-1.jpeg") !important;
}

.home-image-2 {
    background-image: url("../Images/header-2.jpeg");
}

.home-image-3 {
    background-image: url("../Images/header-3.jpeg");
}

.carousel.carousel-slider .control-arrow:hover {
    background: none;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #000000 !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000000 !important;
}

@media only screen and (max-width: 800px) {
    .home-page-content-wrapper {
        flex-direction: column;
    }
    .home-page-content-container {
        width: auto;
        margin: 12px;
    }

    .home-top-section {
        flex-direction: column-reverse;
        justify-content: center
    }

    .home-top-section-header {
        height: 66px;
    }

    .home-text {
        width: 100%;
        text-align: center;
        font-size: 28px;
        line-height: 36px;
    }

    .home-image-wrapper {
        width: 100%;
    }

    .elixir-caret {
        display: none;
    }

    .services-box {
        flex-direction: column;
    }
    
    .services-box-image1, .services-box-image2, .services-box-image3 {
        width: 100% !important;
        left: 0 !important;
        right: 0 !important;
        min-height: 400px !important;
        background-repeat: no-repeat !;
    }

    .services-box-content1, .services-box-content2, .services-box-content3 {
        width: 100% !important;
        right: 0 !important;
        left: 0 !important;
        padding: 0 !important;
    }

    .services-box-2 {
        display: flex;
        flex-direction: column-reverse;
    }
}

@media only screen and (min-width: 801px) and (max-width: 990px){
    .services-box-image1, .services-box-image2, .services-box-image3 {
        background-repeat: no-repeat;
    }
    .home-container{
        width: 100% !important;
        max-width: 100%;
    }
    .services-box-content1, .services-box-content2, .services-box-content3{
        padding: 0 20px !important;
    }
}
